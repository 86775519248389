<template>
  <div class="container">
    <div v-if="permissionError">
      <access-error :itemType="'consultation'" />
    </div>
    <div class="d-flex justify-content-center my-5" v-else-if="loading">
      <!-- <fc-loader></fc-loader> -->
    </div>
    <div v-else>
      <div v-if="consultation">
        <div class="card border-0 mb-3 noprint">
          <div class="card-body">
            <p class="mt-0" style="position: absolute; right: 25px">
              <span
                class="badge text-capitalize me-2"
                :class="
                  consultation.confirmed ? 'bg-primary' : 'bg-warning text-dark'
                "
              >
                {{ consultation.confirmed ? "Confirmed" : "Unconfirmed" }}
              </span>
              <span
                class="badge text-capitalize"
                :class="
                  consultation.paymentStatus === 'paid'
                    ? 'bg-success'
                    : 'bg-danger'
                "
              >
                {{ consultation.paymentStatus | formatStatus }}
              </span>
            </p>
            <h5 v-if="consultation.event" class="fw-bold">
              {{ consultation.event.start | formatDate }}
            </h5>
            <h5 v-if="consultation.practitioner">
              {{ consultation.practitioner.name }}
            </h5>
            <h5>
              <span v-if="consultation.service"
                >{{ consultation.service.name }} - </span
              >{{ consultation.client.name }}
            </h5>
            <div class="mb-2">
              <router-link
                v-if="consultation.order_id"
                class="mt-0"
                :to="/orders/ + consultation.order_id"
              >
                Order #{{ consultation.order_id }}
              </router-link>
            </div>

            <div class="row">
              <div class="col">
                <div class="form-inline">
                  <select
                    v-model="consultation.method"
                    @change="updateConsultation"
                    class="form-control w-50 d-inline"
                    required
                  >
                    <option value="standard">In Person</option>
                    <option value="video">Video</option>
                    <option value="phone">Phone</option>
                  </select>
                  <div
                    class="custom-control custom-switch ms-2"
                    v-if="consultation.method === 'video' && zoom.isIntegrated"
                  >
                    <input
                      v-if="!zoom.loading"
                      type="checkbox"
                      class="custom-control-input"
                      id="zoomSwitch"
                      :checked="zoom.isEnabled"
                      @click="toogleZoomConsultation"
                    />
                    <busy v-else />
                    <label class="custom-control-label" for="zoomSwitch"
                      >Use Zoom</label
                    >
                  </div>
                </div>
              </div>
              <div class="col text-end">
                <button
                  class="btn btn-sm btn-outline-danger me-2"
                  @click="cancelConsultation"
                >
                  <i class="far fa-times-circle me-1"></i>
                  Cancel Consultation
                </button>
                <button
                  class="btn btn-sm btn-outline-primary"
                  @click="showReschedule"
                >
                  <i class="far fa-clock me-1"></i>
                  Re-schedule Consultation
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row mb-3 noprint"
          v-if="room && consultation.method === 'video'"
        >
          <div class="col">
            <div class="card border-0 shadow-sm">
              <div class="card-body">
                <div v-if="!zoom.isEnabled">
                  <h5 class="card-title mb-4">
                    Video Consultation with
                    <a :href="'/clients/' + room.client.id"
                      >{{ room.client.name }} / {{ room.client.email }}</a
                    >
                  </h5>

                  <p class="alert alert-primary mb-4 text-dark">
                    Once you click "Begin Video Consultation", a new tab will be
                    opened, if this is the first time you are using the video
                    consultations, you will need to accept microphone and camera
                    permissions
                    <br />
                    <small>
                      When hosting a video call via the browser we recommend
                      using
                      <a href="https://google.com/chrome" target="_blank"
                        >Google Chrome</a
                      >
                    </small>
                  </p>
                  <div class="row">
                    <div class="col-auto ms-auto my-auto">
                      <a
                        :href="'/consultations/video/' + room.consultation_id"
                        target="_blank"
                        class="btn btn-sm btn-primary float-end"
                      >
                        <i class="far fa-download me-2"></i>Begin Video
                        Consultation
                      </a>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div v-if="zoom.zoom_link">
                    <h5 class="card-title mb-4">
                      Zoom Meeting with
                      <a :href="'/clients/' + room.client.id"
                        >{{ room.client.name }} / {{ room.client.email }}</a
                      >
                    </h5>

                    <p class="alert alert-primary mb-4 text-dark">
                      Clicking 'Begin Zoom Meeting' will start your meeting as
                      the host. You can also manage this via your Zoom
                      Application.
                    </p>
                    <div class="row">
                      <div class="col-auto ms-auto my-auto">
                        <a
                          href="#"
                          target="_blank"
                          class="btn btn-sm btn-secondary float-end"
                        >
                          <i class="fa fa-mail me-2"></i>Resend Meeting to
                          Client
                        </a>
                      </div>
                      <div class="col-auto ms-auto my-auto">
                        <a
                          :href="zoom.zoom_link.zoom_start_url"
                          target="_blank"
                          class="btn btn-sm btn-primary float-end"
                        >
                          <i class="far fa-download me-2"></i>Begin Zoom Meeting
                        </a>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <p>Error getting zoom information</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row mb-3 noprint"
          v-if="
            consultation.method === 'video' || consultation.method === 'other'
          "
        >
          <div class="col">
            <div class="card border-0 shadow-sm">
              <div class="card-body">
                <h5 class="card-title mb-4">External Link</h5>

                <div class="card bg-light mb-3">
                  <div class="card-body">
                    <div v-if="!editLink">
                      {{
                        consultation.external_link
                          ? consultation.external_link
                          : "Not Provided"
                      }}
                    </div>
                    <div v-else>
                      <input
                        class="form-control w-100 mx-auto"
                        placeholder="Provide an external link..."
                        v-model="consultation.external_link"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto ms-auto my-auto">
                    <button
                      class="btn btn-sm btn-outline-primary"
                      v-if="!editLink"
                      @click="editLink = true"
                    >
                      <i class="far fa-edit me-1"></i>
                      Edit
                    </button>
                    <button
                      class="btn btn-sm btn-light me-2"
                      v-if="editLink"
                      @click="cancelEditLink"
                    >
                      <i class="far fa-times me-1"></i>
                      Cancel
                    </button>
                    <button
                      class="btn btn-sm btn-outline-primary"
                      v-if="editLink"
                      @click="saveLink"
                    >
                      <i class="far fa-save me-1"></i>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="associatedOrder">
          <hr class="my-3" />
          <order-overview
            class="noprint"
            :order="associatedOrder"
          ></order-overview>
          <order-items class="noprint" :order="associatedOrder" />
          <order-instalments
            class="noprint"
            :order="associatedOrder"
            @refresh="fetchClientAndRoom"
          ></order-instalments>
          <order-invoice
            :order="associatedOrder"
            class="printit show-when-printing mt-4"
          ></order-invoice>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      id="consultationRescheduleModal"
      v-if="consultation"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content bg-white">
          <div class="modal-header">
            <h5 class="modal-title">
              Reschedule Consultation with {{ consultation.client.name }}
            </h5>
            <button
              type="button"
              class="btn btn-sm-close"
              data-bs-dismiss="modal"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" v-if="consultation">
            <div class="form-group">
              <label>Start Date / Time</label>
              <utc-flat-pickr
                v-model="rescheduleStart"
                :prevent-clear="true"
                class="w-75 p-0 m-0 form-control-sm"
                :req="true"
              ></utc-flat-pickr>
            </div>

            <label>End Date / Time</label>
            <utc-flat-pickr
              v-model="rescheduleEnd"
              :prevent-clear="true"
              class="w-75 p-0 m-0 form-control-sm"
              :req="true"
            ></utc-flat-pickr>
            <div class="custom-control custom-switch mt-4">
              <input
                type="checkbox"
                class="custom-control-input"
                id="reschedule_notification"
                v-model="sendRescheduleNotification"
                :checked="sendRescheduleNotification"
              />
              <label class="custom-control-label" for="reschedule_notification">
                Send client notification of reschedule
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-sm btn-light"
              data-bs-dismiss="modal"
              data-dismiss="modal"
              aria-label="Close"
            >
              Cancel
            </button>
            <button
              class="btn btn-sm btn-outline-primary"
              @click="saveReschedule"
              :disabled="saving"
            >
              <i class="far fa-save me-1"></i>
              Save New Dates
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UtcFlatPickr from "@/views/globals/UtcFlatPickr";
import OrderOverview from "@/views/areas/orders/order-partials/OrderOverview";
import OrderItems from "@/views/areas/orders/order-partials/OrderItems";
import OrderInstalments from "@/views/areas/orders/order-partials/OrderInstalments";
import OrderInvoice from "@/views/areas/orders/order-partials/OrderInvoice";

import AccessError from "../../components/alerts/AccessError";

export default {
  data() {
    return {
      loading: true,
      saving: false,
      consultation: null,
      associatedOrder: null,
      room: null,
      rescheduleStart: null,
      rescheduleEnd: null,
      sendRescheduleNotification: true,
      editLink: false,

      zoom: {
        isIntegrated: false,
        isEnabled: false,
        loading: false,
        zoom_link: null,
      },

      permissionError: false,
    };
  },
  methods: {
    async fetchAssociatedOrder() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL +
          "/consultations/api/" +
          this.$route.params.id +
          "/order"
      );

      this.associatedOrder = data;
    },
    fetchClientAndRoom() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/consultations/api/" +
            this.$route.params.id
        )
        .then(({ data }) => {
          this.consultation = data;
          this.room = data.chat_room;
          if (this.consultation.event) {
            this.rescheduleStart = this.consultation.event.start;
            this.rescheduleEnd = this.consultation.event.end;
          }
          this.loading = false;
          this.fetchAssociatedOrder();
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 403) {
            this.permissionError = true;
          }
        });
    },
    cancelConsultation() {
      var confirmed = confirm(
        "Are you sure you wish to cancel this consultation? This will close any orders as well as clearing the event from your calendar. This is not reversible."
      );
      if (confirmed) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.consultation.client.id +
              "/consultations/" +
              this.consultation.id +
              "/delete"
          )
          .then(({ data }) => {
            if (this.zoom.isEnabled) {
              this.toogleZoomConsultation();
            }

            this.$EventBus.$emit("alert", data);
            this.$router.push(
              "/clients/" + this.consultation.client.id + "#consultations-tab"
            );
          });
      }
    },
    updateConsultation() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.consultation.client.id +
            "/consultations/" +
            this.consultation.id,
          this.consultation
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchClientAndRoom();
        });
    },

    updateZoomMeeting() {
      return this.$axios.post(
        `${process.env.VUE_APP_API_URL}/zoom/reschedule-consultation/${this.consultation.event.id}`
      );
    },

    saveReschedule() {
      var confirmed = true;

      if (this.sendRescheduleNotification) {
        confirmed = confirm(
          "Are you sure you want to re-schedule this consultation? Your client " +
            this.consultation.client.name +
            " will be automatically notified of the change of date."
        );
      }

      if (confirmed) {
        this.saving = true;
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/events/" +
              this.consultation.event.id +
              "/update-dates",
            {
              start: this.rescheduleStart,
              end: this.rescheduleEnd,
              send_notification: this.sendRescheduleNotification,
            }
          )
          .then(async ({ data }) => {
            if (this.zoom.isEnabled) {
              await this.updateZoomMeeting();
            }

            this.$EventBus.$emit("alert", data);
            $("#consultationRescheduleModal").modal("hide");
            this.saving = false;
            this.fetchClientAndRoom();
          });
      }
    },
    showReschedule() {
      $("#consultationRescheduleModal").modal("show");
    },
    saveLink() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.consultation.client.id +
            "/consultations/" +
            this.consultation.id,
          {
            external_link: this.consultation.external_link,
          }
        )
        .then(({ data }) => {
          this.editLink = false;
          this.$EventBus.$emit("alert", data);
        });
    },
    cancelEditLink() {
      this.fetchClientAndRoom();
      this.editLink = false;
    },
    getZoomIntegration() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/zoom/integration")
        .then(({ data }) => {
          if (data.integration) {
            this.zoom.isIntegrated = true;
            this.getZoomMeeting();
          }
        });
    },
    getZoomMeeting() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/zoom/get-meeting/" +
            this.$route.params.id
        )
        .then(({ data }) => {
          if (data.meeting) {
            this.zoom.isEnabled = true;
            this.zoom.zoom_link = data.meeting;
          }
        });
    },
    toogleZoomConsultation() {
      this.zoom.loading = true;
      if (this.zoom.isEnabled) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/zoom/remove-consultation/" +
              this.$route.params.id
          )
          .then(({ data }) => {
            this.zoom.isEnabled = false;
            this.zoom.loading = false;
          });
      } else {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/zoom/add-consultation/" +
              this.$route.params.id
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.zoom.isEnabled = true;
            this.zoom.loading = false;
          });
      }
    },
  },
  computed: {
    initialDurationMins() {
      if (this.consultation.event) {
        const start = moment(this.consultation.event.start);
        const end = moment(this.consultation.event.end);
        return moment.duration(end.diff(start)).asMinutes();
      }
      return 30;
    },
  },
  watch: {
    rescheduleStart(val) {
      let end = moment(val);
      end.add(this.initialDurationMins, "minutes");
      this.rescheduleEnd = end.format("YYYY-MM-DD HH:mm");
    },
  },
  created() {
    this.fetchClientAndRoom();
    this.getZoomIntegration();
  },
  components: {
    OrderInvoice,
    OrderInstalments,
    OrderItems,
    OrderOverview,
    UtcFlatPickr,
    AccessError,
  },
  filters: {
    formatStatus(status) {
      return status.replace(/_/g, " ");
    },
    formatDate(date) {
      return moment.utc(date).local().format("LLLL");
    },
  },
};
</script>

<style></style>
